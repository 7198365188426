import { useContext } from "react";
import { ThemeContext } from "styled-components";

import {
  Panel,
  Typography,
  LayoutGrid,
  CollapsableArticle,
  Link
} from "@anyfin/ui";
import styled, { ThemeProvider } from "styled-components";

const FAQ_CONTENT = [
  {
    question: "Varför lanserar ni Skuldpaus.se?",
    answer: (
      <>
        Tillsammans funderade vi över vad vi kunde göra för att hjälpa människor
        få mer kontroll över ekonomin. Inspirerade av Spelpaus.se, lanserade vi
        tjänsten Kreditblocken. Den har än så länge hjälpt mer än 2 000
        människor att spärra sig från att handla på delbetalning. Människor med
        en snittålder på 28 år.
        <br />
        <br />
        Men det dröjde inte länge innan vi stötte på patrull. Kreditföretaget
        Qliro meddelande att de inte var intresserade av att erbjuda tjänsten på
        ett smidigt sätt genom Anyfin och Resurs Bank informerade att det inte
        var tekniskt möjligt att blockera sig, varken via Anyfin eller på något
        annat sätt. Spiken i kistan för Kreditblocken var när en av de största
        aktörerna inom delbetalningar Klarna slutade att acceptera kunder som
        ville blockera sig med hjälp av Anyfin med förklaringen att de
        “uppdaterat sin säkerhetspolicy”. Detta efter att över 1 600 kunder
        redan hunnit blockera sig från Klarnas delbetalningsalternativ via
        Kreditblocken.
        <br />
        <br />
        Idag är det inte reglerat hur kreditgivare ska hjälpa kunder som vill
        blockera sig från framtida krediter, det är upp till bolagen att själva
        bestämma. Ofta är det en omständig process och informationen om hur du
        som konsument ska gå tillväga är bristande. I de flesta fall krävs det
        att du kontaktar kundservice och därifrån skiljer sig processen mellan
        kreditgivare.
        <br />
        <br />
        Konsumentkrediterna växer lavinartat, den samlade skulden hos
        Kronofogden har mer än fördubblats sedan 2017 och människor efterfrågar
        möjligheten att blockera sig själva från kreditköp. Därför kräver vi nu
        en förändring och anser att mer måste göras för att hjälpa framför allt
        unga som har problem att hantera krediter.
      </>
    )
  },
  {
    question: "Vad är målet med Skuldpaus.se?",
    answer: (
      <>
        Vi vill att det ska lagstiftas om att upprätta ett nationellt system för
        att spärra sig på krediter, likt spelpaus. Som ett steg i att driva
        frågan lanserar vi initiativet Skuldpaus.se. Målet med initiativet är
        att samla in 5000 underskrifter, för att sedan överlämna dem till
        Regeringen och Finansmarknadsminister Åsa Lindhagen.
      </>
    )
  },
  {
    question: "Vem riktar sig initiativet till?",
    answer: (
      <>
        Initiativet Skuldpaus.se riktar sig till alla som tycker att vi måste ta
        skuldsättningen på mer allvar och införa fler verktyg som kan hjälpa
        framförallt unga att få mer kontroll över sin ekonomi.
      </>
    )
  },
  {
    question: "Vem står bakom initiativet?",
    answer: (
      <>
        Initiativet Skuldpaus.se drivs av Mimmi Arndt och Peter Jonsson samt
        några fler anställda från Anyfin som också varit ansvariga för
        utvecklingen av Kreditblocken.
      </>
    )
  },
  {
    question: "Hur deltar man?",
    answer: (
      <>
        För att delta i namninsamlingen går du till Skuldpaus.se, anger ditt
        namn samt e-mailadress så är det klart.
      </>
    )
  },
  {
    question: "Jag har frågor kring initiativet, vem ska jag kontakta?",
    answer: (
      <>
        Alla frågor är välkomna till Mimmi på:
        <br />
        <Link noUnderline href="mailto:mimmi@skuldpaus.se" color="textStrong">
          mimmi@skuldpaus.se
        </Link>
      </>
    )
  }
];

const StyledH2 = styled(Typography.H2)`
  font-family: "Barlow", sans-serif;
`;

const StyledQABox = styled.div`
  margin-bottom: 3rem;
`;

const HorzUnderBar = styled.div`
  height: 0.5rem;
  background-color: #d33a09;
  width: 100%;
`;

const FaqPanel = () => {
  const theme: any = useContext(ThemeContext);
  return (
    <ThemeProvider
      theme={{ background: "#FAEBE4", primary: theme.colors.black }}
    >
      <Panel background mt={[5]} mb={[5]}>
        <LayoutGrid gap={true} base={8}>
          <LayoutGrid.Cell span={[1]} hide={[true, false]} />
          <LayoutGrid.Cell
            span={[8, 6]}
            alignItems="center"
            justifyItems="center"
          >
            <StyledQABox>
              <StyledH2 color="black" gutterBottom={1}>
                Q & A
              </StyledH2>
              <HorzUnderBar />
            </StyledQABox>
            {FAQ_CONTENT.map((question, i) => (
              <CollapsableArticle
                id={question.question + "-" + i}
                key={i}
                question={question.question}
                answer={question.answer}
              />
            ))}
          </LayoutGrid.Cell>
          <LayoutGrid.Cell span={[1]} hide={[true, false]} />
        </LayoutGrid>
      </Panel>
    </ThemeProvider>
  );
};

export default FaqPanel;
