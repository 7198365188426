declare global {
  interface Window {
    analytics: any;
  }
}

const SEGMENT_ID = process.env.NEXT_PUBLIC_SEGMENT_ID;

const thirdPartyScripts: [{ [key: string]: string }] = [
  { url: `//cdn.segment.com/analytics.js/v1/${SEGMENT_ID}/analytics.min.js` }
];

/**
 * https://github.com/segmentio/snippet/blob/master/template/snippet.js
 */
const setupSegment = () => {
  if (typeof window === "undefined") return;

  // Segment presets
  const analytics = (window.analytics = window.analytics || []);

  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;

  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error("Segment snippet included twice.");
    }
    return;
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;

  analytics.SNIPPET_VERSION = "4.1.0";

  // disable pageviews on load, we call trackPage() ourselves
  analytics._loadOptions = { initialPageview: false };

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    "trackSubmit",
    "trackClick",
    "trackLink",
    "trackForm",
    "pageview",
    "identify",
    "reset",
    "group",
    "track",
    "ready",
    "alias",
    "debug",
    "page",
    "once",
    "off",
    "on"
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method) {
    return function (...arg) {
      const args = Array.prototype.slice.call(arg);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };

  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }
};
setupSegment();

/**
 * Defer the loading of third party scripts until after the page has been fully loaded
 * Returns a promise, so that any code relying on this can still execute after loading
 * @returns {Promise[]} Array of promises containing the script.onload events
 */
let loadedScripts = false;
export const deferLoadingScripts = () => {
  if (typeof window === "undefined") return;

  if (loadedScripts) {
    return Promise.resolve(true);
  }

  const loadScripts = () => {
    const loaded = [];
    thirdPartyScripts.forEach(entry => {
      const script = document.createElement("script");
      script.async = true;
      const promise = new Promise((resolve, reject) => {
        script.onload = resolve;
        script.onerror = reject;
      });
      loaded.push(promise);
      if (entry.url) {
        script.src = entry.url;
      } else if (entry.script) {
        script.text = entry.script;
      }
      document.body.appendChild(script);
    });
    loadedScripts = true;
    return Promise.all(loaded);
  };

  return new Promise(resolve => {
    if (document.readyState === "complete") {
      resolve(loadScripts());
    }
    window.addEventListener("load", () => {
      resolve(loadScripts());
    });
  });
};
