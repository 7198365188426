import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import { Panel, Typography, LayoutGrid, Link, Picture } from "@anyfin/ui";

const StyledH3 = styled(Typography.H3)`
  font-family: "Barlow", sans-serif;
`;

const AboutPanel = () => {
  const theme: any = useContext(ThemeContext);
  return (
    <Panel mt={[5, 3]} mb={[5, 3]}>
      <LayoutGrid gap={true} base={8}>
        <LayoutGrid.Cell span={[1]} hide={[true, false]} />
        <LayoutGrid.Cell span={[8, 6]}>
          <StyledH3 color="black">
            Det måste bli lättare att spärra sig från kreditköp
          </StyledH3>
          <Typography size={1} color="black" gutterBottom={[3, 4]}>
            <b>
              Vi svenskar har över 250 miljarder i konsumentkrediter. Det växer
              lavinartat. Samtidigt har den samlade skulden hos Kronofogden mer
              än fördubblats sedan 2017. Psykisk ohälsa kopplat till
              skuldsättning ökar i ungefär samma takt. Flera rapporter visar nu
              på samma sak. Vi måste göra något.
            </b>
            <img
              css={`
                width: 100%;
                object-fit: contain;
                margin-top: 1rem;
              `}
              src="/static/homepage/consumer_loan_mkr_1.gif"
            />
            De flesta har nog tagit del av debatten kring spelmarknaden. Den 1
            januari 2019 infördes en lag som ledde till ett nationellt system
            för självavstängning. Spelpaus.se. Det är ett enkelt sätt för den
            som vill stänga av sig från spelande, sätta gränser i tid och pengar
            eller testa sig själv för att undersöka sitt spelbeteende. Det här
            har hjälpt många människor. Det funkade.
            <br /> <br />
            Så, inspirerade av Spelpaus.se lanserade vi på Anyfin tjänsten
            Kreditblocken. Hittills har den hjälpt drygt 2 000 personer att
            spärra sig från att handla på delbetalning. Men Kreditblocken stötte
            på patrull. Bolag som Klarna, Qliro, Resursbank accepterar inte
            längre att kunder vill blockera med hjälp av Anyfin.
            <br /> <br />
            Och ja, kunden kan alltid vända sig till kreditgivaren själv och
            spärra sig. Men då ska du vända dig till varenda en, var för sig.
            Dessutom är det idag inte reglerat hur kreditgivare ska hjälpa
            kunder som vill blockera sig från framtida krediter. Det är upp till
            bolagen att själva bestämma. Som av en händelse är det ofta
            krångligt och svårt att förstå hur man ska gå tillväga.
            <br /> <br />
            Vi anser att mer måste göras för att få bukt med den ökade
            ohälsosamma skuldsättningen. Vi måste hjälpa dem som har problem att
            hantera krediter. Vi vill att det skapas ett nationellt system där
            den som vill spärra sig själv från att handla på kredit enkelt ska
            kunna göra det. Initiativet kallar vi Skuldpaus.se
            <br /> <br />
            Men vi behöver hjälp. Därför skulle vi vilja be dig om en liten
            tjänst som gör stor skillnad; skriv på vår namninsamling för att
            lagstifta om ett verktyg som gör det enkelt att spärra sig från
            kreditköp. Även om du inte behöver en skuldpaus, vet vi att många
            gör det.
            <br /> <br />
            Målet med namninsamlingen är att samla in minst 5000 underskrifter,
            för att sedan överlämna dem till Regeringen och
            Finansmarknadsminister Åsa Lindhagen.
            <br /> <br />
            <b>
              Mimmi Arndt & Peter Jonsson
              <br /> Initiativtagare till Skuldpaus.se
            </b>
          </Typography>
          <Picture
            path="/static/homepage/MimmiPeter"
            fallback="/static/homepage/MimmiPeter_desktop_1280.jpg"
            alt="Mimmi Arndt & Peter Jonsson"
            css={`
              width: 100%;
              object-fit: contain;
              margin-bottom: 1.5rem;
            `}
          >
            <Picture.Source
              sets={[800, 1280, 1920]}
              types={["jpg"]}
              platform={"desktop"}
              media={`(min-width: ${theme.breakpointsRaw[1]}px)`}
              sizes={`calc(100vw - 4rem)`}
            />
            <Picture.Source
              sets={[500, 1000, 1500]}
              types={["jpg"]}
              platform={"mobile"}
              sizes={`calc(100vw - 2rem)`}
            />
          </Picture>
          <Typography size={0.8} color="black">
            <b>Bild:</b>{" "}
            <Link
              noUnderline
              href="mailto:chris@apanapan.se"
              color="textStrong"
            >
              Christoffer Skogsmo
            </Link>
          </Typography>
        </LayoutGrid.Cell>
        <LayoutGrid.Cell span={[1]} hide={[true, false]} />
      </LayoutGrid>
    </Panel>
  );
};

export default AboutPanel;
