import { useEffect } from "react";
import { Ui } from "@anyfin/ui";

import { trackPage } from "../utils/tracking";
import { deferLoadingScripts } from "../utils/loading";

type LayoutProps = {
  children?: any;
  pageName?: string;
};

const Layout = ({ children = null, pageName = null }: LayoutProps) => {
  useEffect(() => {
    deferLoadingScripts()
      .then(() => {
        // After loading segment, track the page
        const pageNameNew =
          pageName ||
          (!Array.isArray(children) && children.type && children.type.pageName);
        trackPage(pageNameNew || undefined);
      })
      .catch(e => console.warn(e));
  }, [children, pageName]);
  return <Ui>{children}</Ui>;
};

export default Layout;
