import React from "react";
import styled from "styled-components";
import { Panel, media } from "@anyfin/ui";

const StyledHeaderLogo = styled.img`
  max-height: 2rem;
  ${media.desktop`
    max-height: 3rem;
  `}
`;

const Header = () => (
  <Panel mt={[2]} mb={[2]}>
    <StyledHeaderLogo src="/static/skuld_paus.svg" alt="Skuld Paus Logo" />
  </Panel>
);

export default Header;
