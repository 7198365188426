import { useState } from "react";
import { Panel, Typography, LayoutGrid, Grid } from "@anyfin/ui";
import styled, { ThemeProvider } from "styled-components";
import Cookies from "js-cookie";

import SignupForm from "./SignupForm";
import SignupSuccess from "./SignupSuccess";

const SIGNED_UP_COOKIE = "skuld_paus_signed_up";

const StyledH2 = styled(Typography.H2)`
  font-family: "Barlow", sans-serif;
`;

const StyledGrid = styled(Grid)`
  margin-top: 2em;
  width: 100%;
`;

const HeroPanel = () => {
  const [hasUserSignedUp, setHasUserSignedUp] = useState(
    Boolean(Cookies.get(SIGNED_UP_COOKIE))
  );

  const setUserSignedUp = () => {
    Cookies.set(SIGNED_UP_COOKIE, "true", { expires: 7 });
    setHasUserSignedUp(true);
  };
  return (
    <ThemeProvider theme={{ background: "#FAEBE4" }}>
      <Panel align="center" background mt={[5, 9]} mb={[5, 9]}>
        <LayoutGrid gap={true} base={8}>
          <LayoutGrid.Cell span={[1]} hide={[true, false]} />
          <LayoutGrid.Cell
            span={[8, 6]}
            alignItems="center"
            justifyItems="center"
          >
            <StyledH2 color="black">9 704 underskrifter</StyledH2>
            <p>
              Namninsamlingen är stängd och vi tar nu Skuldpaus vidare till
              finansmarknadsministern.
              <br /> Stort tack till alla som skrivit på!
            </p>
            {/* <StyledGrid justifyContent="center" alignItems="center">
              <Grid.Box width={[1, 1, 2 / 3, 1 / 2]} alignItems="center">
                {hasUserSignedUp ? (
                  <SignupSuccess />
                ) : (
                  <SignupForm setUserSignedUp={setUserSignedUp} />
                )}
              </Grid.Box>
            </StyledGrid> */}
          </LayoutGrid.Cell>
          <LayoutGrid.Cell span={[1]} hide={[true, false]} />
        </LayoutGrid>
      </Panel>
    </ThemeProvider>
  );
};

export default HeroPanel;
