export const trackEvent = (eventName, variables = {}, options = {}) => {
  if (typeof window !== "undefined" && window.analytics) {
    analytics.track(eventName, variables, options);
  }
};

export const trackPage = pageName => {
  if (typeof window !== "undefined" && window.analytics) {
    analytics.page(pageName);
  } else {
    console.warn("segment not loaded yet!");
  }
};
