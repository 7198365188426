import React from "react";

import Header from "./Header";
import HeroPanel from "./HeroPanel";
import AboutPanel from "./AboutPanel";
import FaqPanel from "./FaqPanel";

const HomePage = () => {
  return (
    <>
      <Header />
      <HeroPanel />
      <AboutPanel />
      <FaqPanel />
    </>
  );
};

export default HomePage;
